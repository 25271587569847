import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  required,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useEditController,
  maxLength,
  TabbedForm,
  FormTab,
} from "react-admin";
import useFileUpload from "../../hooks/useFileUpload";
import { ResourceEditor } from "../../components/ResourceEditor/ResourceEditor";

import { CreateScreen as HomePageActivitiesCreate } from "../../components/home-page-activities/create";
import { EditScreen as HomePageActivitiesEdit } from "../../components/home-page-activities/edit";

import { CreateScreen as HomePageExperiencesCreate } from "../../components/home-page-experiences/create";
import { EditScreen as HomePageExperiencesEdit } from "../../components/home-page-experiences/edit";

import { CreateScreen as HomePagePropertiesCreate } from "../../components/home-page-properties/create";
import { EditScreen as HomePagePropertiesEdit } from "../../components/home-page-properties/edit";
import { RaEditWrapper } from "../../components/RaEditWrapper";
import { PlainGalleryEditor } from "../../components/PlainGalleryEditor/PlainGalleryEditor";

import { CreateScreen as HeroUploadsCreate } from "../../components/hero-uploads/create";
import { EditScreen as HeroUploadsEdit } from "../../components/hero-uploads/edit";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            heroVideoUploadId: null,
          };

          const resourceFilesReq = {
            heroUploadId: files.heroUpload ?? data.heroUpload.id,
            grid1UploadId: files.grid1Upload ?? data.grid1Upload.id,
            grid2UploadId: files.grid2Upload ?? data.grid2Upload.id,
            grid3UploadId: files.grid3Upload ?? data.grid3Upload.id,
            appUploadId: files.appUpload ?? data.appUpload.id,
          };

          // Clean up fields.
          delete mappedRequest.heroUpload;
          delete mappedRequest.grid1Upload;
          delete mappedRequest.grid2Upload;
          delete mappedRequest.grid3Upload;
          delete mappedRequest.appUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const notify = useNotify();

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    heroUpload: null | any;
    grid1Upload: null | any;
    grid2Upload: null | any;
    grid3Upload: null | any;
    appUpload: null | any;
  }>({
    heroUpload: null,
    grid1Upload: null,
    grid2Upload: null,
    grid3Upload: null,
    appUpload: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: string, files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <RaEditWrapper className="resource-form" mutationMode="pessimistic">
      <TabbedForm
        toolbar={<EditToolbar files={resourceFiles} record={record} />}
      >
        <FormTab label="Hero">
          <PlainGalleryEditor
            resource={"hero-uploads"}
            createNewLabel="Add New Hero Cover"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Hero Carousel"
            CreateScreen={HeroUploadsCreate}
            EditScreen={HeroUploadsEdit}
            hasOrder={true}
            hasEnabled={false}
            limit={5}
          />
        </FormTab>

        <FormTab label="Grid">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="gridHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="gridSubtitle"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(250)]}
            limit={250}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="grid1Upload"
              label="Grid #1 Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "grid1Upload"),
              }}
            >
              {renderImgPreview("grid1Upload")}
            </ImageInput>
          </div>

          <TextInputCounter
            formClassName="form-text-input-field"
            source="grid1Caption"
            label="Grid #1 Caption"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="grid2Upload"
              label="Grid #2 Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "grid2Upload"),
              }}
            >
              {renderImgPreview("grid2Upload")}
            </ImageInput>
          </div>

          <TextInputCounter
            formClassName="form-text-input-field"
            source="grid2Caption"
            label="Grid #2 Caption"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="grid3Upload"
              label="Grid #3 Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "grid3Upload"),
              }}
            >
              {renderImgPreview("grid3Upload")}
            </ImageInput>
          </div>

          <TextInputCounter
            formClassName="form-text-input-field"
            source="grid3Caption"
            label="Grid #3 Caption"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />
        </FormTab>

        <FormTab label="Prop. Carousel">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="carouselHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="carouselSubtitle"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(250)]}
            limit={250}
          />

          <ResourceEditor
            resourceId={"home-page-properties"}
            createNewLabel="Add New Property"
            updatedOrderMsg="Property order updated."
            updatingOrderMsg="Updating property order..."
            heading="Properties"
            renderName={(resource: any) =>
              `${resource.property.area} - ${resource.property.name}`
            }
            CreateScreen={HomePagePropertiesCreate}
            EditScreen={HomePagePropertiesEdit}
            hasOrder={true}
            hasEnabled={true}
          />
        </FormTab>

        <FormTab label="Activities">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="activitiesHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="activitiesSubtitle"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(250)]}
            limit={250}
          />

          <ResourceEditor
            resourceId={"home-page-activities"}
            createNewLabel="Add New Activity"
            updatedOrderMsg="Activity order updated."
            updatingOrderMsg="Updating activity order..."
            heading="Activities"
            renderName={(resource: any) => resource.name}
            CreateScreen={HomePageActivitiesCreate}
            EditScreen={HomePageActivitiesEdit}
            hasOrder={true}
            hasEnabled={true}
          />
        </FormTab>

        <FormTab label="Gear Rental">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="gearsCtaHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="gearsCtaSubtitle"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(250)]}
            limit={250}
          />
        </FormTab>

        <FormTab label="Experiences">
          <ResourceEditor
            resourceId={"home-page-experiences"}
            createNewLabel="Add New Experience"
            updatedOrderMsg="Experience order updated."
            updatingOrderMsg="Updating experience order..."
            heading="Experiences"
            CreateScreen={HomePageExperiencesCreate}
            EditScreen={HomePageExperiencesEdit}
            hasOrder={true}
            hasEnabled={true}
          />
        </FormTab>

        <FormTab label="Social">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="socialHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="socialSubtitle"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(250)]}
            limit={250}
          />
        </FormTab>

        <FormTab label="Journal">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="journalHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="journalSubtitle"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(250)]}
            limit={250}
          />
        </FormTab>
      </TabbedForm>
    </RaEditWrapper>
  );
};
